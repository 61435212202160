import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import urlBackend from "../../config.json";
import newLogo from "./image/newLogo.png";
import ca from "./image/ca.png";

function InfoPageAdminSetting() {

  const { gtin, serial_number } = useParams();

  // Info of Product
  const [productInfo, setProductInfo] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",
    digital_link: "",
    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: ""

  });

  const [supply, setSupply] = useState({
    quantity: ""
  });

  const [sale, setSale] = useState({
    quantity: ""
  });

  //State
  /* const [product, setProduct] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",
    digital_link: "",
    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: "",

    qt_in_hand: "",
    qt_to_be_received: "",
    qt_to_be_packed: "",
    physical_count: ""
  }); */

  // Get List Of Cutters

  useEffect(() => {
    getProduct(gtin, serial_number);
  }, [gtin, serial_number]);

  const getProduct = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/01/${gtin}/21/${serial_number}`)
      .then((res) => {
        setProductInfo(res.data);
      });
  };

  const onInputChange = e => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value })

  }

  const FormHandle = e => {
    e.preventDefault();

    const product = {
      id: productInfo.id,
      scope: productInfo.scope,
      gtin: productInfo.gtin,
      serial_number: productInfo.serial_number,
      product_name: productInfo.product_name,
      origin: productInfo.origin,
      producer: productInfo.producer,
      product_description: productInfo.product_description,
      best_before_date: productInfo.best_before_date,
      price: productInfo.price,
      promotion: productInfo.promotion,
      product_services: productInfo.product_services,
      recycle: productInfo.recycle,
      online_shop: productInfo.online_shop,

    };

    addDataToServer(product)
  }

  const addDataToServer = (product) => {
    console.log(product)
    axios
      .put(urlBackend.BACKEND_SERVER + `/api/resolver/update/product/${product.id}`, product)
      .then(
        (response) => {
          alert("Modif succes");
        }, (error) => {
          console.log(error);

        }
      );
  }

  {/* Supply*/ }


  const onInputSupplyChange = (e) => {
    setSupply({ ...supply, [e.target.name]: e.target.value });
  };

  // Handle Formular
  const FormSupplyHandle = (e) => {
    e.preventDefault();

    const data = {
      id: productInfo.id + getRandomInt(100),
      quantity: supply.quantity,
      product: productInfo.id,
      user: "Admin",
    };

    addSupplyDataToServer(data);
  };

  // Send to server
  const addSupplyDataToServer = (data) => {

    console.log(data);

    axios
      .post(urlBackend.BACKEND_SERVER + `/api/resolver/supply`, data)
      .then((res) => {
        alert("Supply Added success");
      },
        (error) => {
          console.log(error);
          alert("Failed Supply");
        }
      );
  };

  {/* Sale*/ }


  const onInputSaleChange = (e) => {
    setSale({ ...sale, [e.target.name]: e.target.value });
  };

  // Handle Formular
  const FormSaleHandle = (e) => {
    e.preventDefault();

    const data = {
      id: productInfo.id + getRandomInt(100),
      quantity: sale.quantity,
      product: productInfo.id,
      user: "Admin",
    };

    addSaleDataToServer(data);
  };

  // Send to server
  const addSaleDataToServer = (data) => {

    console.log(data);

    axios
      .post(urlBackend.BACKEND_SERVER + `/api/resolver/sale`, data)
      .then((res) => {
        alert("Sale Added success");
      },
        (error) => {
          console.log(error);
          alert("Failed Sale");
        }
      );
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  return (
    <>
      <div className="container-sm">
        <div className="row mt-2">
          {/*Main Content */}

          <div className="card bg-light" >
            <div className="card-body border-0 bg-light">

              <div className="row">

                <div className="text-center">
                  <a href="https://www.c-and-a.com/de/de/shop" target="_blank"
                    rel="noopener,noreferrer">
                    <img
                      src={newLogo}
                      alt="logo"
                      height="50"

                    />
                  </a>
                </div>
                <span className="text-bold fs-5">Digital Product Passport</span>

              </div>
              <hr></hr>

              {/* Header */}

              <div className="text-center">

                <span className="fw-bolder fs-3">{productInfo.product_name}</span>

              </div>

              <div className="card-body">

                <div className="d-grid gap-2 col-8 mx-auto">

                  <div className="row">
                    <div className="card">
                      <div className="card-header"><h5>Product</h5> </div>
                      <div className="card-body">
                        <button className="btn btn-link" type="button">Product picture</button> <br></br>
                        <button className="btn btn-link" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Product information</button>
                      </div>

                    </div>

                  </div>

                  <div className="row">
                    <div className="card">
                      <div className="card-header"><h5>Inventory</h5> </div>
                      <div className="card-body">
                      <button className="btn btn-link" type="button" data-bs-toggle="modal" data-bs-target="#supplyModal">Supplies</button>
                        <br></br>
                        <button className="btn btn-link" type="button" data-bs-toggle="modal" data-bs-target="#saleModal">Sales</button>
                      </div>

                    </div>

                  </div>

                  <div className="row">
                    <div className="card">
                      <div className="card-header"><h5>Tracking</h5> </div>
                      <div className="card-body">
                      <button className="btn btn-link" type="button" data-bs-toggle="modal" >New State</button>
                       
                      </div>

                    </div>

                  </div>

                  <a className="btn btn-link btn-sm" type="button" href={`${productInfo.digital_link}`}>Cancel</a>
                </div>

              </div>
            </div>
          </div>

          {/* Footer */}
          {/* <!-- Modal --> */}
          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title fw-bolder" id="staticBackdropLabel">Edit product information</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <form onSubmit={e => FormHandle(e)} >

                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Article number</label>
                          <input type="text" className="form-control form-control-sm" name="id" value={productInfo.id} onChange={(e) => onInputChange(e)} readOnly />

                        </div>

                      </div>
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">GTIN</label>
                          <input type="text" className="form-control form-control-sm" name="gtin" value={productInfo.gtin} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Serial Number</label>
                          <input type="text" className="form-control form-control-sm" name="serial_number" value={productInfo.serial_number} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Scope</label>
                          <input type="text" className="form-control form-control-sm" name="scope" value={productInfo.scope} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Origin</label>
                          <input type="text" className="form-control form-control-sm" name="origin" value={productInfo.origin} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Price</label>
                          <input type="text" className="form-control form-control-sm" name="price" value={productInfo.price} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Producer</label>
                          <input type="text" className="form-control form-control-sm" name="producer" value={productInfo.producer} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Promotion</label>
                          <input type="text" className="form-control form-control-sm" name="promotion" value={productInfo.promotion} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Recycle</label>
                          <input type="text" className="form-control form-control-sm" name="recycle" value={productInfo.recycle} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Description</label>
                          <textarea className="form-control form-control-sm" name="product_description" value={productInfo.product_description} onChange={(e) => onInputChange(e)}></textarea>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel changes</button>
                      <button type="submit" className="btn btn-primary btn-sm" data-bs-dismiss="modal">Save changes</button>
                    </div>

                  </form>

                </div>


              </div>
            </div>
          </div>

          {/* <!-- Modal Inventory --> */}
          <div className="modal fade" id="inventoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title fw-bolder" id="staticBackdropLabel">Edit product inventory</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <form onSubmit={e => FormHandle(e)} >

                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Article number</label>
                          <input type="text" className="form-control form-control-sm" name="id" value={productInfo.id} onChange={(e) => onInputChange(e)} readOnly />

                        </div>

                      </div>
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">GTIN</label>
                          <input type="text" className="form-control form-control-sm" name="gtin" value={productInfo.gtin} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Serial Number</label>
                          <input type="text" className="form-control form-control-sm" name="serial_number" value={productInfo.serial_number} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Scope</label>
                          <input type="text" className="form-control form-control-sm" name="scope" value={productInfo.scope} onChange={(e) => onInputChange(e)} readOnly />
                        </div>

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Quantity in Hand</label>
                          <input type="text" className="form-control form-control-sm" id="qt_in_hand" name="qt_in_hand" value={productInfo.qt_in_hand} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Quantity to be Received</label>
                          <input type="text" className="form-control form-control-sm" id="qt_to_be_received" name="qt_to_be_received" value={productInfo.qt_to_be_received} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Quantity to be packed</label>
                          <input type="text" className="form-control form-control-sm" id="qt_to_be_packed" name="qt_to_be_packed" value={productInfo.qt_to_be_packed} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                      <div className="col-sm">

                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Physical Count</label>
                          <input type="text" className="form-control form-control-sm" id="physical_count" name="physical_count" value={productInfo.physical_count} onChange={(e) => onInputChange(e)} />
                        </div>

                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel changes</button>
                      <button type="submit" className="btn btn-primary btn-sm" data-bs-dismiss="modal">Save changes</button>
                    </div>

                  </form>

                </div>


              </div>
            </div>
          </div>

          {/* <!-- Modal Suppliy --> */}
          <div className="modal fade" id="supplyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title fw-bolder" id="staticBackdropLabel">Add new supply</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={e => FormSupplyHandle(e)} >

                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Quantity</label>
                          <input type="number" className="form-control form-control-sm" id="quantity" name="quantity" value={supply.quantity} onChange={(e) => onInputSupplyChange(e)} />

                        </div>
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel changes</button>
                      <button type="submit" className="btn btn-primary btn-sm" data-bs-dismiss="modal">Save changes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Modal Sales --> */}
          <div className="modal fade" id="saleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title fw-bolder" id="staticBackdropLabel">Add new sale</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={e => FormSaleHandle(e)} >

                    <div className="row">
                      <div className="col-sm">
                        <div className="mb-3">
                          <label className="form-label form-control-sm ">Quantity</label>
                          <input type="number" className="form-control form-control-sm" id="quantity" name="quantity" value={sale.quantity} onChange={(e) => onInputSaleChange(e)} />

                        </div>
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel changes</button>
                      <button type="submit" className="btn btn-primary btn-sm" data-bs-dismiss="modal">Save changes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
      {/* </div> */}


    </>
  );
}

export default InfoPageAdminSetting;
