import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./menu/Header";
import MenuAdmin from "./menu/MenuAdmin";
import axios from "axios";
import urlBackend from "../../config.json";

function UpdateProduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productInfo, setProductInfo] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",
    digital_link: "",
    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: ""
  });

  useEffect(() => {
    if (id) getProductInfo(id);
  }, [id]);

  const getProductInfo = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/product/${id}`)
      .then((res) => {
        setProductInfo(res.data);
      });
  };

  const onInputChange = (e) => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
  };

  // Handle Formular
  const FormHandle = (e) => {
    e.preventDefault();

    const product = {
      id: productInfo.id,
      scope: productInfo.scope,
      gtin: productInfo.gtin,
      serial_number: productInfo.serial_number,
      product_name: productInfo.product_name,
      origin: productInfo.origin,
      producer: productInfo.producer,
      product_description: productInfo.product_description,
      best_before_date: productInfo.best_before_date,
      price: productInfo.price,
      promotion: productInfo.promotion,
      product_services: productInfo.product_services,
      recycle: productInfo.recycle,
      online_shop: productInfo.online_shop,
      
    }; 

    addDataToServer(product);
  };

  // Send to server
  const addDataToServer = (product) => {
    console.log(product)
 axios
   .put(urlBackend.BACKEND_SERVER + `/api/resolver/update/product/${product.id}`, product)
   .then(
     (response) => {
       alert("Update succes");
       navigate("/admin/home");
     }, (error) => {
       console.log(error);

     }
   );
}

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          {/*Menu */}
          <MenuAdmin />

          {/* Content */}
          <div className="col ">
            <Header />

            {/*Main Content */}
            <div className="row py-3">
              <div className="w-50 mx-auto shadow p-3 mx-5 bg-light">
                <form className="row g-3" onSubmit={(e) => FormHandle(e)}>
                  <fieldset>
                    <legend>Modification</legend>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Article number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Artikel number"
                          name="id"
                          id="id"
                          value={productInfo.id}
                          required={true}
                          readOnly={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">GTIN <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GTIN"
                          id="gtin"
                          name="gtin"
                          pattern="[0-9]{*}"
                          value={productInfo.gtin}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Serial Number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Serial number"
                          id="serial_number"
                          name="serial_number"
                          value={productInfo.serial_number}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">Scope</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Scope"
                          id="scope"
                          name="scope"
                          value={productInfo.scope}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                    <hr></hr>

                    {/* Detail */}
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          id="product_name"
                          name="product_name"
                          value={productInfo.product_name}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Origin</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Origin"
                          id="origin"
                          name="origin"
                          value={productInfo.origin}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Producer</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Producer"
                          id="producer"
                          name="producer"
                          value={productInfo.producer}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          id="product_description"
                          name="product_description"
                          value={productInfo.product_description}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Production Date</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Production Date"
                          id="best_before_date"
                          name="best_before_date"
                          value={productInfo.best_before_date}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Price</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          id="price"
                          name="price"
                          value={productInfo.price}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Promotion</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Promotion"
                          id="promotion"
                          name="promotion"
                          value={productInfo.promotion}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Services</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Services"
                          id="product_services"
                          name="product_services"
                          value={productInfo.product_services}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Recycle</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recycle"
                          id="recycle"
                          name="recycle"
                          value={productInfo.recycle}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Online Shop</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Online Shop"
                          id="online_shop"
                          name="online_shop"
                          value={productInfo.online_shop}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <p className="text-start"><span className="text-danger">*</span> required</p>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button className="btn btn-secondary me-md-2">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>

                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdateProduct;
