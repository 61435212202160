import * as React from "react";
import { useNavigate } from "react-router-dom";
import logo from "./image/logo.png";
function Login() {
  const navigate = useNavigate();

  // Handle Formular
  const FormHandle = (e) => {

    navigate("/admin/home");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            {" "}
            
          </a>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav"></div>
          </div>
        </div>
      </nav>

      <hr></hr>
      <img src={logo} alt="" width="150" height="144" className="mx-auto" />
      <div className="w-50 mx-auto shadow p-5 mt-2 bg-light">
        <div className="card">
          <div className="card-header col-form-label-lg">Anmeldung</div>
          <div className="card-body">
          <div className="w-50 mx-auto shadow p-3 mx-3 bg-light mt-5 mb-5">
                <form onSubmit={(e) => FormHandle(e)}>
                  <fieldset>
                    <div className="mb-3">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Admin"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                       
                        value="password"
                        required={true}
                       
                      />
                    </div>
                   

                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </fieldset>
                </form>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
