import React, { useState, useEffect } from "react";
import Header from "./menu/Header";
import MenuAdmin from "./menu/MenuAdmin";
import axios from "axios";
import { Link } from "react-router-dom";
import urlBackend from "../../config.json";
import logo from "./image/logo.png";

function Start() {
  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          {/*Main Content */}
          <div className="w-50 mx-auto shadow p-5 mt-5 bg-light ">
            <div className="card ">
              <div className="card-body">
                {/* Header */}
                <nav className="navbar sticky-top navbar-light bg-primary">
                  <div className="container-fluid">
                    <a className="navbar-brand text-white" href="#">
                      EECC Digital PassPort
                    </a>
                    <Link to="/admin/login" className="d-flex" title="Login">
                      <i
                        className="bi-person-circle text-white"
                        style={{ fontSize: 22 }}
                      ></i>
                    </Link>
                  </div>
                </nav>
                <img
                  src={logo}
                  alt=""
                  width="140"
                  height="134"
                  className="mx-auto mt-3 my-3"
                />

                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row mt-3">
                      <form className="d-flex">
                        <input
                          className="form-control me-2"
                          type="search"
                          placeholder="Search by Article number"
                          aria-label="Search"
                        />
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                    <hr></hr>
                    <div className="row mt-3">
                      <form className="d-flex">
                        <input
                          className="form-control me-2"
                          type="search"
                          placeholder="Search by GTIN"
                          aria-label="Search"
                        />
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* Footer */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Start;
