import React from "react";
import logo from "../image/logo.png";

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container-fluid">
          <a href="/admin/home" className="navbar-brand">
            {" "}
            <img src={logo} alt="" width="60" height="54" className="mx-auto" />
          </a>

          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">EECC Digital Product Passport</div>
          </div>
          
          <a href="/admin" className="nav-link">
            <i className="bi bi-box-arrow-right text-primary" title="Abmelden" style={{ fontSize: 22 }}> </i>
          </a>
        </div>
      </nav>
      <hr></hr>
    </>
  );
}

export default Header;
