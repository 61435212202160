import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./menu/Header";
import MenuAdmin from "./menu/MenuAdmin";
import axios from "axios";
import urlBackend from "../../config.json";

function AddProduct() {
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",

    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: "",
  });

  const onInputChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  // Handle Formular
  const FormHandle = (e) => {
    e.preventDefault();

    const data = {
      id: product.id,
      scope: product.scope,
      gtin: product.gtin,
      serial_number: product.serial_number,
      product_name: product.product_name,
      origin: product.origin,
      producer: product.producer,
      product_description: product.product_description,
      best_before_date: product.best_before_date,
      price: product.price,
      promotion: product.promotion,
      product_services: product.product_services,
      recycle: product.recycle,
      online_shop: product.online_shop,
    };

    addDataToServer(data);
  };

  // Send to server
  const addDataToServer = (data) => {
    
    console.log(data);
    
    axios
      .post(urlBackend.BACKEND_SERVER + `/api/resolver/product`, data)
      .then((res) => {
        alert("Added success");
        navigate("/admin/home");
      },
       (error) => {
        console.log(error);
        alert("Failed");
    }
      );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          {/*Menu */}
          <MenuAdmin />

          {/* Content */}
          <div className="col ">
            <Header />

            {/*Main Content */}
            <div className="row py-3">
              <div className="w-50 mx-auto shadow p-3 mx-5 bg-light">
                <form className="row g-3" onSubmit={(e) => FormHandle(e)}>
                  <fieldset>
                    <legend>New Product</legend>
                    <hr></hr>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Article number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Artikel number"
                          name="id"
                          id="id"
                          value={product.id}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">GTIN <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GTIN"
                          id="gtin"
                          name="gtin"
                          pattern="[0-9]{*}"
                          value={product.gtin}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Serial Number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Serial number"
                          id="serial_number"
                          name="serial_number"
                          pattern="[0-9]{*}"
                          value={product.serial_number}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">Scope <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Scope"
                          id="scope"
                          name="scope"
                          value={product.scope}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                    <hr></hr>

                    {/* Detail */}
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          id="product_name"
                          name="product_name"
                          value={product.product_name}
                          required={true}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Origin</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Origin"
                          id="origin"
                          name="origin"
                          value={product.origin}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Producer</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Producer"
                          id="producer"
                          name="producer"
                          value={product.producer}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          id="product_description"
                          name="product_description"
                          value={product.product_description}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Production Date</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Production Date"
                          id="best_before_date"
                          name="best_before_date"
                          value={product.best_before_date}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Price</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          id="price"
                          name="price"
                          value={product.price}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Promotion</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Promotion"
                          id="promotion"
                          name="promotion"
                          value={product.promotion}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Services</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Services"
                          id="product_services"
                          name="product_services"
                          value={product.product_services}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Recycle</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recycle"
                          id="recycle"
                          name="recycle"
                          value={product.recycle}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Online Shop</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Online Shop"
                          id="online_shop"
                          name="online_shop"
                          value={product.online_shop}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <p className="text-start"><span className="text-danger">*</span> required</p>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddProduct;
