import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      handy: 100,
      xs: 900,
      sm: 1200,
      md: 1536,
      lg: 2000,
      xxl: 2650,
      xxxl: 3000,
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#08954C",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          fontSize: "0.7rem !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff",
        },
        head: {
          backgroundColor: "#08954C !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { position: "sticky" },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: "700",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: "sticky",
          color: "black",
        },
      },
    },
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    primary: {
      main: "#0E86D4", // blue Grotto
    },
    secondary: {
      main: "#08954C",
    },
    text: {
      title: "#ffff",
      option1: "#0E86D4 !important",
      option2: "#003060 !important",
    },
    borderColor: "#003060", //navi blue
    background: {
      default: "#ffff ",
      option1: "#68BBE3 !important",
      option2: "#40E0D0 !important",
      titleBox: "#08954C", // green
      inputBox: "#ffff ", // white
      footer: "#08954C", // green
    },
  },
});
