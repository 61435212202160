import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import urlBackend from "../../config.json";
import axios from "axios";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AdminLoginDialog from "./AdminLoginDialog";
import NormalLoginDialog from "./NormalLoginDialog";

import {
  Box,
  BottomNavigationAction,
  BottomNavigation,
  ListItem,
  Paper,
  ListItemText,
  DialogActions,
  List,
  AppBar,
  DialogTitle,
  Dialog,
  Toolbar,
  Typography,
  TextField,
  Button,
  Link,
  styled,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import LoginIcon from "@mui/icons-material/Login";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import strickjacke from ".././../Images/canda/strickjacke.png";
import ca_logo from ".././../Images/canda/CA.jpg";
const strickjackeImages = [
  ".././../Images/canda/strickjacke.png",
  ".././../Images/canda/strickjacke2.png",
  ".././../Images/canda/strickjacke3.png",
];
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  margin: "0.8rem",
  fontWeight: "600",

  fontSize: "0.7rem",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  margin: "0.8rem",

  backgroundColor: "#0F2A47",
  fontSize: "0.7rem",
  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));
const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "white !important",
    textAlign: "center",
    position: "absolut",
    color: "black",
  },
  paper: {
    height: "13vh",
  },
  titleBox: {
    color: theme.palette.text.title,
    textAlign: "center",
    fontSize: "200%",
    fontWeight: "bold",
  },
}));
const Main = () => {
  let { id } = useParams();
  const ref = useRef(null);
  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState(0);
  const [linked, setLinked] = useState("waiting");
  const [product, setProduct] = useState([
    { name: "Produktsname", value: "", authen: "0" },
    {
      name: "Beschreibung",
      value: "",
      authen: "0",
    },
    { name: "Hersteller", value: "", authen: "0" },
    { name: "Verbrauchsdatum", value: "", authen: "0" },
    { name: "Preis", value: "", authen: "0" },
    { name: "Recyceln", value: "", authen: "0" },
    { name: "Onlineshop", value: "", authen: "0" },
    { name: "ID", value: "", authen: "1" },
    { name: "GTIN", value: "", authen: "1" },
    { name: "Origin", value: "", authen: "1" },
    { name: "Produktdienstleistungen", value: "", authen: "1" },
  ]);
  const [productBackup, setProductBackup] = useState([
    { name: "Produktsname", value: "", authen: "0" },
    {
      name: "Beschreibung",
      value: "",
      authen: "0",
    },
    { name: "Hersteller", value: "", authen: "0" },
    { name: "Verbrauchsdatum", value: "", authen: "0" },
    { name: "Preis", value: "", authen: "0" },
    { name: "Recyceln", value: "", authen: "0" },
    { name: "Onlineshop", value: "", authen: "0" },
    { name: "ID", value: "", authen: "1" },
    { name: "GTIN", value: "", authen: "1" },
    { name: "Origin", value: "", authen: "1" },
    { name: "Produktdienstleistungen", value: "", authen: "1" },
  ]);
  const [normalLogin, setNormalLogin] = useState(false);
  const [openNormalLoginDialog, setOpenNormalLoginDialog] = useState(false);
  const [adminLogin, setAdminLogin] = useState(false);
  const [openAdminLoginDialog, setOpenAdminLoginDialog] = useState(false);
  const [liked, setLiked] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const handleLiked = () => {
    setLiked(!liked);
  };
  const handleCloseNormalLoginDialog = () => {
    setOpenNormalLoginDialog(false);
  };

  const handleCloseAdminLoginDialog = () => {
    setOpenAdminLoginDialog(false);
  };

  const handleLoginAdmin = () => {
    setOpenAdminLoginDialog(false);
    setAdminLogin(true);
  };
  const handleLogoutAdmin = () => {
    setOpenAdminLoginDialog(false);
    setAdminLogin(false);
  };
  const handleLoginNormal = () => {
    setOpenNormalLoginDialog(false);
    setNormalLogin(true);
  };
  const handleLogoutNormal = () => {
    setOpenNormalLoginDialog(false);
    setNormalLogin(false);
  };
  const saveProduct = (data) => {
    let newProduct = [
      {
        name: "Produktsname",
        value: data.product_name,
        authen: "0",
      },
      {
        name: "Beschreibung",
        value: data.product_description,
        authen: "0",
      },
      { name: "Hersteller", value: data.producer, authen: "0" },
      { name: "Preis", value: data.price, authen: "0" },
      { name: "Artikel Nummer", value: data.id, authen: "1" },
      { name: "Recyceln", value: data.recycle, authen: "0" },
      { name: "Onlineshop", value: data.online_shop, authen: "0" },
      { name: "GTIN", value: data.gtin, authen: "1" },
      { name: "Origin", value: data.origin, authen: "1" },
      {
        name: "Verbrauchsdatum",
        value: data.best_before_date,
        authen: "0",
      },
      {
        name: "Produktdienstleistungen",
        value: data.product_services,
        authen: "1",
      },
    ];
    if (data.online_shop.includes("http")) {
      setLinked("true");
    } else {
      setLinked("false");
    }
    setProduct(newProduct);
    setProductBackup(newProduct);
  };
  const getProductInfo = (id) => {
    axios

      .get(urlBackend.BACKEND_SERVER + "/api/resolver/product/" + id, {})
      .then((response) => {
        saveProduct(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(linked);
  }, [adminLogin, normalLogin, linked]);
  useEffect(() => {
    getProductInfo(id.toString());
  }, [id]);
  /* useEffect(() => {
    if (linked === "true") {
      redirectToDestination();
    }
  }, [product, linked]); */
  /*  const redirectToDestination = () => {
    let urlObject = product.find((item) => item.name === "Onlineshop");
    window.location.href = `${urlObject.value}`;
  }; */
  const classes = useStyles();
  const updateFieldChanged = (name, index, value) => {
    let newArr = product.map((item, i) => {
      if (index === i) {
        return { ...item, value: value };
      } else {
        return item;
      }
    });
    setProduct(newArr);
    console.log(product);
  };
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleChangeProduct = () => {
    let productObject = {};

    product.map((item) => {
      if (item.name === "ID") productObject["id"] = item.value;
      if (item.name === "Beschreibung")
        productObject["product_description"] = item.value;
      if (item.name === "Produktsname")
        productObject["product_name"] = item.value;
      if (item.name === "Hersteller") productObject["producer"] = item.value;
      if (item.name === "Preis") productObject["price"] = item.value;
      if (item.name === "GTIN") productObject["gtin"] = item.value;
      if (item.name === "Digitale Link")
        productObject["digital_link"] = item.value;
      if (item.name === "Produktdienstleistungen")
        productObject["product_services"] = item.value;
      if (item.name === "Recyceln") productObject["recycle"] = item.value;
      if (item.name === "Onlineshop") productObject["online_shop"] = item.value;
      if (item.name === "Origin") productObject["origin"] = item.value;
    });

    productObject = JSON.stringify(productObject);
    axios

      .post(
        urlBackend.BACKEND_SERVER + "/api/resolver/product",
        productObject,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        saveProduct(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenConfirm(false);
    setAdminLogin(false);
  };
  if ("false" === "false") {
    return (
      <Box sx={{ pb: 7, overflow: "hidden" }} ref={ref}>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <BookOnlineIcon
              sx={{
                mr: 1,
              }}
              color="black !important"
            />
            <Typography
              sx={{
                fontFamily: "monospace",
                fontWeight: "900",
                color: "black",
              }}
            >
              Digital Product Pass
            </Typography>

            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "right",
              }}
            >
              {" "}
              <a href="https://www.c-and-a.com/">
                <img
                  src={ca_logo}
                  alt="LogoCA"
                  style={{
                    marginLeft: "auto",
                    marginRight: "0",
                    display: "flex",
                    width: "15w",
                    height: "10vw",
                  }}
                />{" "}
              </a>
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            width: "100vw",
            height: 450,
          }}
        >
          <img
            src={strickjacke}
            alt="Logo"
            style={{
              width: "80vw",

              height: 450,
              // backgroundColor: "#EAE2B7",
              backgroundColor: "#003049",
              borderRadius: "0 0 10% 10%",
            }}
          />

          {/* <Box
            sx={{
              width: "100vw",
              height: 450,
              // backgroundColor: "#EAE2B7",
              backgroundColor: "#003049",
              borderRadius: "0 0 10% 10%",
            }}
          /> */}
        </Box>
        <NormalLoginDialog
          open={openNormalLoginDialog}
          logged={normalLogin}
          handleClose={() => handleCloseNormalLoginDialog}
          handleLogin={() => handleLoginNormal}
          handleLogout={() => handleLogoutNormal}
        />
        <AdminLoginDialog
          open={openAdminLoginDialog}
          logged={adminLogin}
          handleClose={() => handleCloseAdminLoginDialog}
          handleLogin={() => handleLoginAdmin}
          handleLogout={() => handleLogoutAdmin}
        />
        <List>
          {product.map(({ name, value, authen }, index) => {
            if (!name.includes("Verbrauchsdatum")) {
              if (adminLogin) {
                return (
                  <ListItem>
                    <TextField
                      id="standard-search"
                      label={name}
                      type="search"
                      variant="standard"
                      value={value}
                      onChange={(event) => {
                        updateFieldChanged(name, index, event.target.value);
                      }}
                    />
                  </ListItem>
                );
              } else {
                return (
                  <ListItem>
                    {authen === "1" && normalLogin ? (
                      <ListItemText primary={name} secondary={value} />
                    ) : authen === "1" && !normalLogin ? null : authen ===
                        "0" && name !== "Onlineshop" ? (
                      <ListItemText primary={name} secondary={value} />
                    ) : authen === "0" && name === "Onlineshop" ? (
                      <ListItemText>
                        <Typography>{name}</Typography>
                        <Link href={value}> {value}</Link>
                      </ListItemText>
                    ) : (
                      <div style={{ height: "0" }} />
                    )}
                  </ListItem>
                );
              }
            }
          })}
          <Box>
            <ColorButtonConfirm
              style={adminLogin ? null : { display: "none" }}
              autoFocus
              onClick={() => {
                setOpenConfirm(true);
              }}
            >
              Bestätigen
            </ColorButtonConfirm>
            <ColorButtonCancel
              style={adminLogin ? null : { display: "none" }}
              autoFocus
              onClick={() => setProduct(productBackup)}
            >
              Zurücksetzen
            </ColorButtonCancel>
          </Box>
        </List>
        <Dialog onClose={handleCloseConfirm} open={openConfirm}>
          <DialogTitle>Wollen Sie wirklich die Daten ändern?</DialogTitle>
          <DialogContent>
            <DialogActions className={classes.diaglogAction}>
              <>
                <ColorButtonCancel
                  autoFocus
                  onClick={() => setOpenConfirm(false)}
                >
                  Abbrechen
                </ColorButtonCancel>
                <ColorButtonCancel
                  autoFocus
                  onClick={() => handleChangeProduct()}
                >
                  Bestätigen
                </ColorButtonCancel>
              </>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={3}
        >
          <BottomNavigation
            style={{
              backgroundColor: "white",
              color: "black",
              fontSize: "small",
              boxShadow: "0px 10px 5px #888, 0px -1px 5px #888",
            }}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label={!adminLogin ? "Als Admin" : "Als Admin"}
              showLabel
              icon={<ModeEditIcon />}
              style={
                adminLogin
                  ? { color: "#40C9C4", fontSize: "small", fontWeight: "700" }
                  : { color: "black", fontSize: "small", fontWeight: "700" }
              }
              onClick={() => setOpenAdminLoginDialog(true)}
            />

            <BottomNavigationAction
              showLabel
              label={normalLogin ? "Als Kunde" : "Als Kunde"}
              icon={<LoginIcon />}
              style={
                normalLogin
                  ? { color: "#40C9C4", fontSize: "small", fontWeight: "700" }
                  : { color: "black", fontSize: "small", fontWeight: "700" }
              }
              onClick={() => setOpenNormalLoginDialog(true)}
            />
            <BottomNavigationAction
              label="Gefällt mir"
              icon={liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              style={
                liked
                  ? { color: "#40C9C4", fontSize: "small", fontWeight: "700" }
                  : { color: "black", fontSize: "small", fontWeight: "700" }
              }
              onClick={() => setLiked(!liked)}
              showLabel
            />
            <BottomNavigationAction
              label="Teilen"
              icon={<ShareIcon />}
              style={{ color: "black", fontSize: "small", fontWeight: "700" }}
              showLabel
            />
          </BottomNavigation>
        </Paper>
      </Box>
    );
  }
};

export default Main;
