import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import MainCanda from "./Components/PagesCanda/Main.jsx";

import Login from "./Components/PagesAdmin/Login.js";
import Home from "./Components/PagesAdmin/Home";
import AddProduct from "./Components/PagesAdmin/AddProduct";
import Detail from "./Components/PagesAdmin/Detail";
import Start from "./Components/PagesAdmin/Start";
import UpdateProduct from "./Components/PagesAdmin/UpdateProduct";
import InfoPage from "./Components/PagesAdmin/InfoPage";
import InfoPageUser from "./Components/PagesAdmin/InfoPageUser";
import InfoPageAdmin from "./Components/PagesAdmin/InfoPageAdminSetting";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/home" element={<Home />} />
          <Route path="/admin/addProduct" element={<AddProduct />} />
          <Route path="/admin/details/:id" element={<Detail />} />
          <Route path="/admin/update/:id" element={<UpdateProduct />} />
          <Route
            path="/01/:gtin/21/:serial_number/:id"
            element={<MainCanda />}
          />
          <Route
            path="/01/:gtin/21/:serial_number"
            element={<InfoPage/>}
          />
          <Route
            path="/01/:gtin/21/:serial_number/user"
            element={<InfoPageUser/>}
          />
          <Route
            path="/01/:gtin/21/:serial_number/admin"
            element={<InfoPageAdmin/>}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
