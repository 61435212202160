import React, { useState, useEffect } from "react";
import Header from "./menu/Header";
import MenuAdmin from "./menu/MenuAdmin";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import QRCode from "react-qr-code";
import urlBackend from "../../config.json";
import logo from "./image/ca-logo.png";
import ca from "./image/ca.png";

function Detail() {
  const { id } = useParams();
  //State
  const [product, setProduct] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",
    digital_link: "",
    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: "",
  });

  // Get List Of Cutters

  useEffect(() => {
    if (id) getProduct(id);
  }, [id]);

  const getProduct = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/product/${id}`)
      .then((res) => {
        setProduct(res.data);
      });
  };

  // QR-Code

  const [eyeOuterRadius] = useState(10); // mesti disesuaiin dengan qrsize
  const [qrSize] = useState(128);
  const [logoSize] = useState(qrSize / 4);
  const [qrContent] = useState();
  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          {/*Menu */}
          <MenuAdmin />

          {/* Content */}
          <div className="col ">
            <Header />

            {/*Main Content */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/home">
                    <i className="bi-house"></i> Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
            <div className="row py-3">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Product
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div className="accordion-body">
                      {/** Body first accordion */}

                      <div className="w-75 mx-auto shadow p-5 mt-2 bg-light">
                        <div className="row mb-2">
                          {/* <div className="col-8"> */}
                          {/* <div class="card mb-3" style={{width: '540px'}}> */}
                          <div className="card mb-3">
                            <div className="row g-0 py-5">
                              <div className="col-md-4">
                                {/* <img src="..." class="img-fluid rounded-start" alt="..."> */}
                                <img
                                  src={ca}
                                  alt=""
                                  width="200"
                                  height="auto"
                                  className="mx-auto"
                                />
                              </div>
                              <div className="col-md-4">
                                <table className="table table-sm">
                                  <tbody>
                                    <tr>
                                      <td className="text-start fw-bolder">
                                        Name
                                      </td>
                                      <td className="text-start">
                                        {product.product_name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bolder">
                                        Article number
                                      </td>
                                      <td className="text-start">
                                        {product.id}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bolder">
                                        GTIN
                                      </td>
                                      <td className="text-start">
                                        {product.gtin}
                                      </td>
                                    </tr>
                                    <tr scope="row">
                                      <td className="text-start fw-bolder">
                                        Serial Number
                                      </td>
                                      <td className="text-start">
                                        {product.serial_number}
                                      </td>
                                    </tr>
                                    <tr scope="row">
                                      <td className="text-start fw-bolder">
                                        Scope
                                      </td>
                                      <td className="text-start">
                                        {product.scope}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div className="card-body"></div>
                              </div>
                              <div className="col-md-4 ">
                                <QRCode
                                  value={product.digital_link}
                                  logoImage="logo"
                                  logoWidth={logoSize}
                                  logoHeight={logoSize}
                                  size={qrSize}
                                  ecLevel={"M"}
                                  eyeRadius={[
                                    {
                                      // top/left eye
                                      outer: [
                                        0,
                                        eyeOuterRadius,
                                        0,
                                        eyeOuterRadius,
                                      ],
                                      inner: [
                                        0,
                                        eyeOuterRadius / 4,
                                        0,
                                        eyeOuterRadius / 4,
                                      ],
                                    },
                                    {
                                      // top/right eye
                                      outer: [
                                        0,
                                        eyeOuterRadius,
                                        0,
                                        eyeOuterRadius,
                                      ],
                                      inner: [
                                        0,
                                        eyeOuterRadius / 4,
                                        0,
                                        eyeOuterRadius / 4,
                                      ],
                                    },
                                    {
                                      // bottom/left
                                      outer: [
                                        eyeOuterRadius,
                                        eyeOuterRadius,
                                        eyeOuterRadius,
                                        0,
                                      ],
                                      inner: [
                                        eyeOuterRadius / 4,
                                        eyeOuterRadius / 4,
                                        eyeOuterRadius / 4,
                                        0,
                                      ],
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="card text-start mb-3">
                            <div className="card-body ">
                              <h4>Links</h4>
                              <p>
                                {" "}
                                <span className="fw-bolder">
                                  Digital link:{" "}
                                </span>
                                <a
                                  href={`${product.digital_link}`}
                                  target="_blank"
                                  rel="noopener,noreferrer"
                                >
                                  {product.digital_link}
                                </a>
                              </p>
                              <p>
                                {" "}
                                <span className="fw-bolder">
                                  Online shop:{" "}
                                </span>{" "}
                                <a
                                  href={`${product.online_shop}`}
                                  target="_blank"
                                  rel="noopener,noreferrer"
                                >
                                  {product.online_shop}
                                </a>
                              </p>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="card">
                            <div className="card-body">
                              <table className="table table-sm">
                                <h4 className="text-start">Details</h4>
                                <tbody>
                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Origin
                                    </td>
                                    <td className="text-start">
                                      {product.origin}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Price
                                    </td>
                                    <td className="text-start">
                                      {product.price}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Producer
                                    </td>
                                    <td className="text-start">
                                      {product.producer}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Promotion
                                    </td>
                                    <td className="text-start">
                                      {product.promotion}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Recycle
                                    </td>
                                    <td className="text-start">
                                      {product.recycle}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="text-start fw-bolder">
                                      Description
                                    </td>
                                    <td className="text-start">
                                      {product.product_description}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Inventory - Tracking
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body">
                      <strong>
                        The place reserved for tracking and inventory.
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Detail;
