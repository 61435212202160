import React, { useState, useEffect } from "react";
import Header from "./menu/Header";
import MenuAdmin from "./menu/MenuAdmin";
import axios from "axios";
import { Link } from "react-router-dom";
import urlBackend from "../../config.json";
import QRCode from "react-qr-code";

function Home() {
  //State
  const [products, setProducts] = useState([]);

  // Get List Of Cutters

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/products`)
      .then((res) => {
        setProducts(res.data);
      });
  };

  const deleteOne = (id) => {
    axios
      .delete(urlBackend.BACKEND_SERVER + `/api/resolver/delete/${id}`)
      .then((reponse) => {
        console.log(reponse.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // QR-Code

  const [eyeOuterRadius] = useState(10); // mesti disesuaiin dengan qrsize
  const [qrSize] = useState(40);
  const [logoSize] = useState(qrSize / 4);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          {/*Menu */}
          <MenuAdmin />

          {/* Content */}
          <div className="col ">
            <Header />

            {/*Main Content */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  <i className="bi-house"></i> Home
                </li>
              </ol>
            </nav>
            <div className="row py-3 mx-auto">
              <div className="col">
                <div className="card border-0">
                  <div className="card-body border">
                    <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search by Article number"
                        aria-label="Search"
                      />
                      <button className="btn btn-outline-success" type="submit">
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0">
                  <div className="card-body border">
                    <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search by GTIN"
                        aria-label="Search"
                      />
                      <button className="btn btn-outline-success" type="submit">
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-3 mx-auto">
              <div className="card border-0">
                <div className="card-header border text-start">
                  List of products
                </div>
                <div className="card-body border">
                  <table
                    id="example"
                    className="table border table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th>GTIN</th>
                        <th>Article number</th>
                        <th>Product Name</th>
                        {/* <th>Digital Link</th> */}
                        <th>Online Shop</th>
                        <th>Action</th>
                        <th>GenerateQR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => (
                        <tr key={product.id}>
                          <td>{product.gtin}</td>
                          <td>{product.id}</td>
                          <td>{product.product_name}</td>
                          {/* <td>
                            <a
                              href={`${product.digital_link}`}
                              target="_blank"
                              rel="noopener,noreferrer"
                            >
                              Open
                            </a>
                          </td> */}

                          <td>
                            <a
                              href={`${product.online_shop}`}
                              target="_blank"
                              rel="noopener,noreferrer"
                            >
                              Open
                            </a>
                          </td>

                          <td>
                            <Link
                              to={`/admin/details/${product.id}`}
                              className="mx-3"
                              title="Details"
                            >
                              <i
                                className="bi-eye"
                                style={{ fontSize: 20 }}
                              ></i>
                            </Link>
                            <Link to={`/admin/update/${product.id}`}
                              className="mx-3" title="Edit">
                              <i
                                className="bi-pencil-square"
                                style={{ fontSize: 20 }}
                              ></i>
                            </Link>
                            <a
                              className="mx-3 text-danger"
                              title="Delete"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "This product will be permanently deleted."
                                  )
                                ) {
                                  deleteOne(product.id);
                                  window.location.reload();
                                }
                              }}
                            >
                              <i
                                className="bi-trash"
                                style={{ fontSize: 20 }}
                              ></i>
                            </a>
                          </td>
                          <td>
                            <QRCode
                              value={product.digital_link}
                              logoImage="logo"
                              logoWidth={logoSize}
                              logoHeight={logoSize}
                              size={qrSize}
                              ecLevel={"M"}
                              eyeRadius={[
                                {
                                  // top/left eye
                                  outer: [
                                    0,
                                    eyeOuterRadius,
                                    0,
                                    eyeOuterRadius,
                                  ],
                                  inner: [
                                    0,
                                    eyeOuterRadius / 4,
                                    0,
                                    eyeOuterRadius / 4,
                                  ],
                                },
                                {
                                  // top/right eye
                                  outer: [
                                    0,
                                    eyeOuterRadius,
                                    0,
                                    eyeOuterRadius,
                                  ],
                                  inner: [
                                    0,
                                    eyeOuterRadius / 4,
                                    0,
                                    eyeOuterRadius / 4,
                                  ],
                                },
                                {
                                  // bottom/left
                                  outer: [
                                    eyeOuterRadius,
                                    eyeOuterRadius,
                                    eyeOuterRadius,
                                    0,
                                  ],
                                  inner: [
                                    eyeOuterRadius / 4,
                                    eyeOuterRadius / 4,
                                    eyeOuterRadius / 4,
                                    0,
                                  ],
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
