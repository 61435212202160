import { makeStyles } from "@mui/styles";
import * as React from "react";
import axios from "axios";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField } from "@mui/material";

import {
  styled,
  Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";

const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",
  fontSize: "0.7rem",
  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  fontSize: "0.7rem",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles((theme) => ({
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const LoginDialog = (props) => {
  const { open, handleClose, handleLogin, handleLogout, logged } = props;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleNext = () => {
    if ((pageNumber + 1) % numPages === 0) {
      setPageNumber(1);
    } else {
      setPageNumber((pageNumber + 1) % numPages);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "medium",
          }}
          id="draggable-dialog-title"
        >
          {"Als Admin einloggen"}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required"
              label="Username"
              defaultValue=""
            />
            <TextField
              required
              id="outlined-required"
              label="Passwort"
              defaultValue=""
            />
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          {logged ? (
            <>
              <ColorButtonCancel autoFocus onClick={handleClose()}>
                Abschließen
              </ColorButtonCancel>
              <ColorButtonCancel autoFocus onClick={handleLogout()}>
                Ausloggen
              </ColorButtonCancel>
            </>
          ) : (
            <>
              <ColorButtonCancel autoFocus onClick={handleClose()}>
                Abschließen
              </ColorButtonCancel>
              <ColorButtonConfirm autoFocus onClick={handleLogin()}>
                Einlogen
              </ColorButtonConfirm>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginDialog;
