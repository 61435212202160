import * as React from "react";


function MenuAdmin() {
  return (
    <>
      <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-secondary">
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <a
            href="/admin"
            className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
          >
            <i className="fs-4 bi-three-dots-vertical"></i>
            <span className="fs-5 d-none d-sm-inline">Menu</span>
          </a>

          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
            id="menu"
          >
            <li className="nav-item">
              <a href="/admin/home" className="nav-link align-middle px-0">
                <i className="fs-4 bi-house text-white"></i>{" "}
                <span className="ms-1 d-none d-sm-inline text-white">Home</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="/admin/addProduct"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-plus-circle text-white"></i>{" "}
                <span className="ms-1 d-none d-sm-inline text-white">
                  Add product
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/admin/addProduct"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-person-plus text-white"></i>{" "}
                <span className="ms-1 d-none d-sm-inline text-white">
                  Add user
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/admin/addProduct"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-people text-white"></i>{" "}
                <span className="ms-1 d-none d-sm-inline text-white">
                  Users
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/admin/addProduct"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-gear text-white"></i>{" "}
                <span className="ms-1 d-none d-sm-inline text-white">
                  Setting
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default MenuAdmin;
