import React, { useState, useEffect } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";
import urlBackend from "../../config.json";
import newLogo from "./image/newLogo.png";
import ca from "./image/ca.png";

function InfoPageUser() {

  const { gtin, serial_number } = useParams();
  //State
  const [product, setProduct] = useState({
    id: "",
    scope: "",
    gtin: "",
    serial_number: "",
    digital_link: "",
    product_name: "",
    origin: "",
    producer: "",
    product_description: "",
    best_before_date: "",
    price: "",
    promotion: "",
    product_services: "",
    recycle: "",
    online_shop: "",
  });

  const [inventory, setInventory] = useState({
    product: "",
    available: "",
    sale: "",
    supply: "",
   
    
  });
  // Get List 

  useEffect(() => {
    getProduct(gtin, serial_number);
    getInventory(gtin, serial_number);
  }, [gtin, serial_number]);

  const getProduct = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/01/${gtin}/21/${serial_number}`)
      .then((res) => {
        setProduct(res.data);
      });
  };

  const getInventory = () => {
    axios
      .get(urlBackend.BACKEND_SERVER + `/api/resolver/inventory/${gtin}/${serial_number}`)
      .then((res) => {
        setInventory(res.data);
      });
  };

  return (
    <>
      <div className="container-sm">
        <div className="row mt-2">
          {/*Main Content */}

          <div className="card bg-light" >
            <div className="card-body border-0 bg-light">

              <div className="row">

                <div className="text-center">
                  <a href="https://www.c-and-a.com/de/de/shop" target="_blank"
                    rel="noopener,noreferrer">
                    <img
                      src={newLogo}
                      alt="logo"
                      height="50"

                    />
                  </a>
                </div>
                <span className="text-bold fs-5">Digital Product Passport</span>

              </div>
              <hr></hr>

              {/* Header */}

              <div className="text-center">
                <img
                  src={ca}
                  alt=""
                  width="250"
                  height="300"
                  className="mx-auto img-fluid"
                />
                <br></br>
                <span className="fw-bolder fs-3">{product.product_name}</span>
                <br></br>
                <span className="fw-normal fs-5">{product.price} </span>
              </div>

              <div className="card-body">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Product information
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Article number" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.id} readOnly />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="GTIN" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.gtin} readOnly />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Serial Number" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.serial_number} readOnly />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Scope" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.scope} readOnly />
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Production
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Origin" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.origin} readOnly />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Price" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.price} readOnly />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Producer" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.producer} readOnly />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Promotion" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.promotion} readOnly />
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Reclycling & Description
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Recycle" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" value={product.recycle} readOnly />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Description" readOnly />
                                <textarea className="form-control-plaintext form-control-sm" value={product.product_description} readOnly></textarea>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Inventory
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Quantity available" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" readOnly value={inventory.available} />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Quantity supplied" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" readOnly value= {inventory.supply} />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="Quantity sold" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" readOnly value= {inventory.sale} />
                              </div>
                            </form>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Track & Trace
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="received" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" readOnly value="16" />
                              </div>
                            </form>
                          </div>
                          <div className="col-sm">
                            <form>
                              <div className="mb-3">
                                <input type="text" className="form-control-plaintext form-control-sm fw-bolder fs-6" value="delivered" readOnly />
                                <input type="text" className="form-control-plaintext form-control-sm" readOnly value="23" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="d-grid gap-2  col-6 mx-auto">
                      
                      <button type="button" className="d-flex justify-content-center btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Edit
                      </button>
                      <a className="btn btn-secondary btn-sm" type="button"  href={`${product.digital_link}`}>Logout</a>
                    </div>
                  </div>
                </div>

              </div>

              {/* Footer */}
              {/* <!-- Modal --> */}
              <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fw-bolder" id="staticBackdropLabel">Login</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label for="exampleInputEmail1" className="form-label">Name</label>
                          <input type="text" className="form-control form-control-sm" id="exampleInputEmail1" value="Admin" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                          <label for="exampleInputPassword1" className="form-label">Password</label>
                          <input type="password" className="form-control form-control-sm" value="password" id="exampleInputPassword1" />
                        </div>

                        <a type="submit" className="btn btn-primary btn-sm" href={`${product.digital_link + "/admin"}`}>Submit</a>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* </div> */}
        </div>

      </div>

    </>
  );
}

export default InfoPageUser;
